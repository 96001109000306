@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    @apply bg-gray-900;
}

.h1 {
    @apply text-4xl font-bold;
}

.h2 {
    @apply text-2xl font-semibold;
}

.link {
    @apply text-black font-bold underline;
}

.logo {
    @apply relative inline-block font-black text-3xl select-none;
}

.logo span {
    @apply absolute transition-all;
}

.logo span:nth-child(1) {
    animation-name: rainbow;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

.logo span:nth-child(2) {
    animation-name: rainbow;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-delay: 250ms;
}

.logo span:nth-child(3) {
    animation-name: rainbow;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-delay: 500ms;
}

.logo span:nth-child(5) {
    animation-name: rainbow;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    @apply opacity-0 transition-all;
}

.logo span:nth-child(6) {
    @apply static opacity-0;
}

.logo:hover span:nth-child(2) {
    transform: translate(2px, -2px);
}

.logo:hover span:nth-child(3) {
    transform: translate(4px, -4px);
}

.logo:hover span:nth-child(4) {
    transform: translate(6px, -6px);
}

.logo:hover span:nth-child(5) {
    transform: translate(6px, -6px);
    opacity: 1;
}

@keyframes rainbow {
    from {
        @apply text-red-500;
    }

    25% {
        @apply text-yellow-500;
    }

    50% {
        @apply text-green-500;
    }

    75% {
        @apply text-blue-500;
    }

    to {
        @apply text-purple-500;
    }
}